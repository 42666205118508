.notfound {
  padding: 50px 0;
  box-sizing: border-box;
  h1 {
    font-weight: 700;
    font-size: 50px;
    line-height: 130%;
    color: $primary_color;
    letter-spacing: 1px;
    text-align: center;
  }
  h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 130%;
    text-align: center;
    color: $secondary_color;
    margin: 40px 0;
  }
  img {
    width: 80%;
    display: block;
    margin: auto;
  }
}
