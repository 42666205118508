.expertise {
  section {
    margin: auto;
    padding: 100px 0;
    h1 {
      font-weight: 700;
      font-size: 50px;
      line-height: 130%;
      color: $primary_color;
      letter-spacing: 1px;
      text-align: center;
      margin-bottom: 50px;
    }
    div.coloumns {
      display: grid;
      article {
        background-color: #f5f5f5;
        border: 5px solid #cccccc31;

        padding: 40px;
        position: relative;
        div.cover {
          img {
            max-height: 500px;
            width: 100%;
            display: block;
            margin: auto;
          }
          h2 {
            font-weight: 700;
            margin-bottom: 40px;
            display: block;
            font-size: 30px;
            line-height: 180%;
            color: $secondary_color;
            letter-spacing: 1px;
            text-align: center;
          }
          &:hover {
            div.content {
              opacity: 1;
            }
          }
        }
        div.content {
          position: absolute;
          display: none;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 9;
          background-color: #005193ee;
          opacity: 0;
          display: grid;
          align-items: center;
          transition: 0.5s ease;
          div.container {
            width: 90%;
            margin: auto;

            color: #ffffff;
            h2 {
              color: $secondary_color;
              font-size: 20px;
              margin-bottom: 20px;
            }
            ul {
              margin-left: 20px;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 519px) {
    section {
      div.coloumns {
        grid-template-columns: 1fr;
        margin: 10px;
        article {
          div.cover {
            img {
              height: 600px;
            }
          }
          div.content {
            div.container {
              font-size: 14px;
              line-height: 160%;
            }
          }
        }
      }
    }
  }

  @media only screen and (min-width: 520px) and (max-width: 979px) {
    section {
      div.coloumns {
        grid-template-columns: 1fr;
        article {
          div.cover {
            img {
              height: 600px;
            }
          }
        }
      }
    }
  }

  @media only screen and (min-width: 980px) and (max-width: 1229px) {
  }

  @media only screen and (min-width: 1230px) {
    section {
      width: 90%;
      div.coloumns {
        grid-template-columns: 1fr 1fr;
        article {
          margin: 20px;
          div.cover {
            img {
              max-height: 500px;
            }
          }
          div.content {
            div.container {
              font-size: 16px;
              line-height: 170%;
            }
          }
        }
      }
    }
  }
}
