.header {
  header {
    margin: auto;
    display: grid;
    font-family: $font;
    padding-top: 30px;
    nav {
      font-size: 15px;
      a {
        display: inline-block;
        color: $primary_color;
        letter-spacing: 1px;
        text-decoration: none;
        font-weight: 500;
        padding-bottom: 8px;
        border-bottom: 5px solid #ffffff;
        svg {
          margin-right: 10px;
          color: $secondary_color;
        }
        &:hover,
        &.current {
          border-bottom: 5px solid $primary_color;
        }
        &:nth-child(5) {
          margin-right: 50px;
        }
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
          svg {
            margin-right: 0;
          }
          margin: 25px 5px;
        }
      }
    }
    @media only screen and (max-width: 519px) {
      width: 95%;
      grid-template-columns: 1fr;
      div.logo {
        img {
          width: 90%;
          display: block;
          margin: auto;
        }
      }
      nav {
        nav {
          width: 90%;
          margin: auto;
          a {
            margin: 15px 10px;
            font-size: 16px;
          }
        }
      }
    }

    @media only screen and (min-width: 520px) and (max-width: 979px) {
      width: 90%;
      grid-template-columns: 1fr;
      div.logo {
        img {
          width: 90%;
          display: block;
          margin: auto;
        }
      }
      nav {
        nav {
          width: 90%;
          margin: auto;
          a {
            margin: 20px 20px;
            font-size: 16px;
          }
        }
      }
    }

    @media only screen and (min-width: 980px) and (max-width: 1229px) {
    }

    @media only screen and (min-width: 1230px) {
      width: 90%;
      grid-template-columns: 250px 1fr;
      div.logo {
        img {
          width: 100%;
        }
      }
      nav {
        text-align: right;
        a {
          margin: 25px 25px;
          font-size: 18px;
        }
      }
    }
  }
}
