footer {
  background-color: #f5f5f5;
  width: 100%;
  div.container {
    box-sizing: border-box;
    margin: auto;
    display: grid;
    div {
      font-size: 16px;
      line-height: 190%;
      letter-spacing: 1px;
      padding: 0 5vh;
      h2 {
        margin-bottom: 20px;
        padding-bottom: 20px;
        font-size: 20px;
        color: $primary_color;
        border-bottom: 1px dashed rgba(0, 0, 0, 0.5);
      }
      span {
        color: rgba(0, 0, 0, 0.6);
      }
      a {
        text-decoration: none;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }

  @media only screen and (max-width: 519px) {
    padding: 15px 0;
    div.container {
      grid-template-columns: repeat(1, 1fr);
      padding: 15px 0;
      div {
        font-size: 15px;
        margin-bottom: 40px;
        img {
          width: 150px;
          display: inline-block;
          margin: 1vh 3vw;
        }
      }
    }
  }

  @media only screen and (min-width: 520px) and (max-width: 979px) {
    padding: 15px 0;
    div.container {
      grid-template-columns: repeat(1, 1fr);
      padding: 15px 0;
      div {
        font-size: 15px;
        margin-bottom: 40px;
        img {
          width: 150px;
          display: inline-block;
          margin: 1vh 3vw;
        }
      }
    }
  }

  @media only screen and (min-width: 980px) and (max-width: 1229px) {
  }

  @media only screen and (min-width: 1230px) {
    padding: 30px 0;
    div.container {
      width: 90%;
      grid-template-columns: repeat(3, 1fr);
      div {
        font-size: 16px;
        img {
          width: 12vw;
          display: block;
          margin: 2vh 0;
        }
      }
    }
  }
}
