@import "reset";

$primary_color: #005193;
$secondary_color: #f39892;
@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.0.0/animate.min.css");
@import url("https://fonts.googleapis.com/css2?family=Alata&display=swap");
//$font: "Lora", serif;
$font: "Alata", sans-serif;

.App,
body,
html,
#root {
  height: 100%;
  width: 100%;
}

body {
  font-family: $font;
}

.App {
  display: grid;
  grid-template-rows: auto 1fr auto;
}

@import "header";
@import "footer";
@import "index";
@import "about";
@import "expertise";
@import "team";
@import "contact";
@import "clients";
@import "404";

@media only screen and (max-width: 519px) {
}

@media only screen and (min-width: 520px) and (max-width: 979px) {
}

@media only screen and (min-width: 980px) and (max-width: 1229px) {
}

@media only screen and (min-width: 1230px) {
}
