section.about {
  h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 130%;
    text-align: center;
    color: $secondary_color;
    margin: 40px 0;
  }
}

div.division {
  background-color: #f5f5f5;
  h2 {
    margin-top: 0;
  }
  section {
    display: grid;

    width: 90%;
    margin: auto;
    div {
      margin: 15px;
      height: 28vh;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      h3 {
        box-sizing: border-box;
        text-align: center;
        background-color: #005193c0;
        font-size: 20px;
        display: inline-block;
        padding: 15px 0;
        width: 100%;
        position: absolute;
        bottom: 10px;
        color: #ffffff;
      }
    }
  }
  @media only screen and (max-width: 519px) {
    padding: 50px 0;
    section {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @media only screen and (min-width: 520px) and (max-width: 979px) {
    padding: 50px 0;
    section {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @media only screen and (min-width: 980px) and (max-width: 1229px) {
  }

  @media only screen and (min-width: 1230px) {
    padding: 100px 0;
    section {
      grid-template-columns: repeat(3, auto);
      grid-column: 3 / span 2;
    }
  }
}

div.approach {
  h2 {
    margin-top: 0;
  }
  section {
    width: 90%;
    margin: auto;
    display: grid;

    div {
      padding-top: 20px;
      img {
        width: 100px;
        margin: auto;
        display: block;
        opacity: 0.4;
      }
      h2 {
        text-align: center;
        font-size: 20px;
        margin-top: 20px;
        color: #000000;
      }
    }
  }
  @media only screen and (max-width: 519px) {
    padding: 50px 0;
    section {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @media only screen and (min-width: 520px) and (max-width: 979px) {
    padding: 50px 0;
    section {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @media only screen and (min-width: 980px) and (max-width: 1229px) {
  }

  @media only screen and (min-width: 1230px) {
    padding: 100px 0;
    section {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}

div.history {
  border-bottom: 1px dashed #000000;
  border-top: 1px dashed #000000;
  line-height: 150%;
  main {
    margin: auto;
    text-align: center;
    p {
      padding: 30px 30px;
      border-image: linear-gradient(to right, $primary_color 0%, $secondary_color 100%);
      border-image-slice: 1;
      border-style: solid;
      &:nth-child(even) {
        border-top: 3px solid;
        border-bottom: 3px solid;
        border-right: 3px solid;
      }
      &:nth-child(odd) {
        border-left: 3px solid;
      }
    }
  }
  @media only screen and (max-width: 519px) {
    main {
      width: 90%;
    }
  }

  @media only screen and (min-width: 520px) and (max-width: 979px) {
    main {
      width: 90%;
    }
  }

  @media only screen and (min-width: 980px) and (max-width: 1229px) {
  }

  @media only screen and (min-width: 1230px) {
    main {
      width: 600px;
    }
  }
}
