.clients {
  padding: 50px 0;
  box-sizing: border-box;
  h1 {
    font-weight: 700;
    font-size: 50px;
    line-height: 130%;
    color: $primary_color;
    letter-spacing: 1px;
    text-align: center;
  }
  h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 130%;
    text-align: center;
    color: $secondary_color;
    margin: 40px 0;
  }
  section {
    margin: auto;
    width: 90%;
    display: grid;

    div {
      background-color: #f5f5f5;

      padding: 25px;
      align-items: center;
      display: grid;
      img {
        width: 100%;
        margin: auto;
        display: block;
        align-self: center;
      }
    }
  }
  @media only screen and (max-width: 519px) {
    section {
      grid-template-columns: repeat(2, 1fr);
      div {
        margin: 5px;
      }
    }
  }

  @media only screen and (min-width: 520px) and (max-width: 979px) {
    section {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media only screen and (min-width: 980px) and (max-width: 1229px) {
  }

  @media only screen and (min-width: 1230px) {
    section {
      grid-template-columns: repeat(6, 1fr);
      div {
        height: 150px;
        padding: 50px;
        margin: 15px;
      }
    }
  }
}
